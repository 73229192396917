body {
  font-family: var(--base-text-font-type);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: var(--base-text-color);
  box-sizing: border-box;
}

:root {
  --base-text-color: #053e2f;
  --base-text-font-type: "Poppins";
  --white: #fff;
  --box-shadow: 4px 4px 37px rgba(0, 47, 0, 0.06);
  --border-radius: 24px;
}

div.gm-style .gm-style-iw-c {
  padding: 0 !important;
  border-radius: 24px;
}

button.gm-ui-hover-effect {
  top: 0px !important;
  right: 0px !important;
}

div.gm-style-iw-d {
  overflow: auto !important;
}

.star-ratings .star {
  stroke: #ffbb0c;
  stroke-width: 2px;
  border-radius: 50%;
}
